import React from "react";
import "./style.css"; // Puedes personalizar los estilos según el diseño de tu web

export const Loader = ({ callback }) => {
  const durationStage = 2000;
  const [loading, setLoading] = React.useState(true);
  const dots = [];
  const totalDots = 300;
  const radius = 100; // Radio de la esfera

  for (let i = 0; i < totalDots; i++) {
    const phi = Math.acos(-1 + (2 * i) / totalDots); // Ángulo vertical
    const theta = Math.sqrt(totalDots * Math.PI) * phi; // Ángulo horizontal

    const x = radius * Math.sin(phi) * Math.cos(theta);
    const y = radius * Math.sin(phi) * Math.sin(theta);
    const z = radius * Math.cos(phi);

    dots.push(
      <span
        key={i}
        className="dot"
        style={{
          transform: `translate3d(${x}px, ${y}px, ${z}px)`,
        }}
      ></span>
    );
  }

  React.useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, durationStage);
    const timer2 = setTimeout(() => {
      callback("main");
    }, durationStage + 1000);
    return () => {
      clearTimeout(timer);
      clearTimeout(timer2);
    };
  }, []);

  return (
    <>
      <div className="loader-container" style={!loading ? { animation: "slideUp 0.5s forwards" } : { animation: "" }}>
        {loading && (
          <>
            <div className="sphere">{dots}</div>
            <div className="loader-text">Cargando</div>
          </>
        )}
      </div>
    </>
  );
};
