import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  userConfig: {
    componentActive: null,
  },
};

const userConfigSlice = createSlice({
  name: "aplicationConfig",
  initialState,
  reducers: {
    setComponentActive: (state, action) => {
      state.userConfig.componentActive = action.payload;
    },
  },
});

export const { setComponentActive } = userConfigSlice.actions;
export default userConfigSlice.reducer;
