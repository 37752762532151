import "./style.css";

export const Badget = ({ component, border = "0px", position = "right" }) => {
  const setStyles = () => {
    const styles = {};
    styles.border = border;

    if (position === "right") {
      styles.marginRight = "-100px";
      styles.animation = "slideRight 1s forwards";
    }

    if (position === "left") {
      styles.marginLeft = "-100px";
      styles.animation = "slideLeft 1s forwards";
    }

    if (position === "top") {
      styles.marginTop = "-100px";
      styles.animation = "slideTop 1s forwards";
    }

    return styles;
  };

  return (
    <div className="badget" style={setStyles()}>
      {component}
    </div>
  );
};
