import "./style.css";

export const About = () => {
  return (
    <div className="about">
      <div className="about-title">Hola, soy Antonio Ruiz.</div>
      <div className="about-description">
        Desde que comencé mi viaje como programador hace más de 15 años realizando trabajos remotos para crear productos digitales. Soy tranquilo,
        curioso y constantemente trato de ir mejorando.
      </div>
    </div>
  );
};
