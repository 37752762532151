import "./style.css";

export const Techs = () => {
  const values = [
    { name: "React", value: 17, color: "var(--primary-color)" },
    { name: "Js", value: 18, color: "#8817ef" },
    { name: "HTML", value: 16, color: "#7817ef" },
    { name: "CSS", value: 17, color: "#6817ef" },
    { name: "Git", value: 15, color: "#5817ef" },
    { name: "PHP", value: 11, color: "#4817ef" },
    { name: "MySQL", value: 11, color: "#3817ef" },
  ];

  const Bar = ({ data }) => {
    return (
      <div className="bar" style={{ backgroundColor: data.color, height: data.value + "0px" }}>
        <div className="bar-info">{data.name}</div>
      </div>
    );
  };
  return (
    <>
      <div className="techs-name box-title">Tecnologías</div>
      <div className="techs">
        {values.map((value) => (
          <Bar data={value} />
        ))}
      </div>
    </>
  );
};
