import React from "react";
import { Loader } from "./components/loader";
import { Info } from "./components/info";
import { Badget } from "./components/badget";
import { About } from "./components/about";
import { Techs } from "./components/techs";
import { Contact } from "./components/contact";
import { Menu } from "./components/menu";
import { BadgetMain } from "./components/main";
import { Soft } from "./components/soft";

function App() {
  const [state, setState] = React.useState("loader");

  const handleState = (newState) => {
    setState(newState);
  };

  const HandlerState = () => {
    switch (state) {
      case "loader":
        return <Loader callback={handleState} />;
      case "main":
        return (
          <div className="main">
            <div className="main-side">
              <Badget component={<About />} position={"left"} />
              <Badget component={<Techs />} position={"left"} />
            </div>
            <div className="main-center">
              <Badget component={<Info />} position={"top"} border={"0.5px var(--primary-color) solid"} />
              <BadgetMain />
            </div>
            <div className="main-side">
              <Badget component={<Soft />} position={"right"} />
              <Badget component={<Contact />} position={"right"} />
            </div>
          </div>
        );
      default:
        return <Loader />;
    }
  };
  return <HandlerState />;
}

export default App;
