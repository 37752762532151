import "./style.css";

export const Works = () => {
  const proyectos = [
    { name: "ERP de facturación", img: "https://antonioruiz.net/img/gestorem.jpg", url: "https://antonioruiz.net/apps/software-facturacion-online/" },
    {
      name: "Openbank (colaboración)",
      img: "https://3.bp.blogspot.com/-DldvfinjpDQ/WUqrX6tEYiI/AAAAAAAAb4A/pGheOx52CrMnW2-MWa-YDLdRv2BqQTKzwCLcBGAs/s1600/opne2.jpg",
      url: "#",
    },
    { name: "ERP + CRM", img: "https://antonioruiz.net/img/fondo_cima_cableados.jpg", url: "#" },
    { name: "Analytics Tool", img: "https://antonioruiz.net/img/fondo_analytics.jpg", url: "#" },
  ];

  return (
    <div className="works">
      {proyectos.map((p) => (
        <div className="work">
          <div className="work-img">
            <img src={p.img} alt={p.name} />
          </div>
          <div className="work-info">{p.name}</div>
        </div>
      ))}
    </div>
  );
};
