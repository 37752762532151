import React, { useState } from "react";

export const BoxHunt = () => {
  const [score, setScore] = useState(0);
  const [segundos, setSegundos] = useState(0);
  const [position, setPosition] = useState({ top: "50%", left: "50%" });
  const [intervalo, setIntervalo] = useState(null); // Guardar la referencia del intervalo

  // Cambiar la posición del cuadro aleatoriamente
  const moveBox = () => {
    const top = Math.random() * 80 + "%"; // Evita que se salga del contenedor
    const left = Math.random() * 80 + "%";
    setPosition({ top, left });
  };

  // Manejar el clic en el cuadro
  const handleClick = () => {
    if (score === 0) iniciarContador();
    if (segundos < 30) {
      setScore(score + 1);
      moveBox();
    }
  };

  function iniciarContador() {
    if (intervalo) clearInterval(intervalo); // Detener el intervalo anterior si existe

    let segundos = 0;
    const nuevoIntervalo = setInterval(() => {
      segundos++;
      setSegundos(segundos);
      // Detener el contador después de 30 segundos
      if (segundos >= 30) {
        clearInterval(nuevoIntervalo);
      }
    }, 1000);

    setIntervalo(nuevoIntervalo); // Guardar el nuevo intervalo
  }

  const reset = () => {
    iniciarContador();
    setScore(0);
    setSegundos(0); // Reiniciar los segundos cuando se reinicia
  };

  return (
    <div className="">
      <div className="box">
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div>
            <b>Box Hunt</b>
          </div>
          {segundos === 30 && (
            <div onClick={reset} style={{ cursor: "pointer" }}>
              Reiniciar
            </div>
          )}
        </div>
        <div>Trata de cazar el mayor número de cajas en 30 segundos.</div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          lineHeight: "1.5",
          fontSize: "1rem",
          marginTop: "5px",
          marginBottom: "10px",
        }}
      >
        <div>
          Puntuación <br />
          <b>{score}</b>
        </div>
        <div style={{ textAlign: "right" }}>
          Tiempo <br />
          <b>{segundos}</b>
        </div>
      </div>
      <div style={styles.container}>
        <div style={{ ...styles.box, ...position }} onClick={handleClick}></div>
      </div>
    </div>
  );
};

const styles = {
  container: {
    width: "100%",
    height: "175px",
    position: "relative",
    margin: "0 auto",
    overflow: "hidden",
    border: "1px var(--secondary-color) solid",
    borderRadius: "10px",
    backgroundColor: "rgba(0, 0, 0, 0.4)",
  },
  box: {
    width: "40px",
    height: "40px",
    backgroundColor: "var(--primary-color)",
    position: "absolute",
    cursor: "pointer",
    borderRadius: "10px",
  },
};
