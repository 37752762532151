import { useEffect, useState } from "react";
import "./style.css";

export const Info = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    // Iniciar la animación después de que el componente se haya montado
    setIsVisible(true);
  }, []);

  return (
    <div className={`container ${isVisible ? "show" : ""}`}>
      <div className="text">AR</div>
      <div className="text-info">Diseño y desarrollo de forma optimizada y eficiente aplicaciones elegantes.</div>
    </div>
  );
};
