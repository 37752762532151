import "./style.css";

export const Soft = () => {
  const values = [
    { name: "Visual Studio Code", value: 80, color: "var(--primary-color)" },
    { name: "Jira", value: 95, color: "#8817ef" },
    { name: "Figma", value: 75, color: "#7817ef" },
    { name: "Github", value: 95, color: "#6817ef" },
    { name: "Photoshop", value: 85, color: "#5817ef" },
    { name: "cPanel", value: 80, color: "#4817ef" },
    { name: "Plesk", value: 80, color: "#3817ef" },
  ];

  const Circles = ({ data }) => {
    const activeCount = Math.round((data.value / 100) * 10);
    return (
      <div>
        <div className="soft-circle-name">{data.name}</div>
        <div className="container">
          {Array.from({ length: 10 }).map((_, index) => (
            <div key={index} className={`smallDot ${index < activeCount ? "active" : ""}`}></div>
          ))}
        </div>
        <div className="small-circles"></div>
      </div>
    );
  };

  return (
    <>
      <div className="soft-name box-title">Soft</div>
      <div className="soft">
        {values.map((data) => (
          <Circles data={data} />
        ))}
      </div>
    </>
  );
};
