import React, { useState } from "react";

const GuessTheNumber = () => {
  const [targetNumber, setTargetNumber] = useState(Math.floor(Math.random() * 100) + 1);
  const [guess, setGuess] = useState("");
  const [guessDone, setGuessDone] = useState(false);
  const [feedback, setFeedback] = useState("Intenta adivinar el número (1-100)");
  const [attempts, setAttempts] = useState(0);

  const handleGuess = () => {
    const numericGuess = parseInt(guess, 10);
    setAttempts((prev) => prev + 1);

    if (numericGuess === targetNumber) {
      setFeedback(`Felicidades! Lo has adivinado en ${attempts + 1} intentos.`);
      setGuessDone(true);
    } else if (numericGuess > targetNumber) {
      setFeedback("Muy alto! Inténtalo de nuevo.");
      document.getElementById("guessNumber").focus();
    } else {
      setFeedback("Muy bajo! Inténtalo de nuevo.");
      document.getElementById("guessNumber").focus();
    }

    setGuess("");
  };

  const reset = () => {
    setGuessDone(false);
    setFeedback("Intenta adivinar el número (1-100)");
    setAttempts(0);
  };

  return (
    <div className="">
      <div className="box">
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <h3>Adivina el número</h3>
          {guessDone && (
            <h4 onClick={reset} style={{ cursor: "pointer" }}>
              Reiniciar
            </h4>
          )}
        </div>
        <div>Intenta adivinar el número con los menores intentos posibles.</div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          lineHeight: "1.5",
          fontSize: "1rem",
          marginTop: "25px",
          marginBottom: "10px",
        }}
      >
        <div>
          Intentos <br />
          <b>{attempts}</b>
        </div>
      </div>

      <p>{feedback}</p>
      <input id="guessNumber" type="number" value={guess} onChange={(e) => setGuess(e.target.value)} style={{ textAlign: "center" }} />
      <button onClick={handleGuess} style={{ marginLeft: "10px", padding: "10px 20px", fontSize: "16px" }}>
        Adivinar
      </button>
    </div>
  );
};

export default GuessTheNumber;
