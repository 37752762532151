import { useState } from "react";
import { BoxHunt } from "./boxHunt";
import "./style.css";
import GuessTheNumber from "./guessTheNumber";

export const Games = () => {
  const [game, setGame] = useState(<BoxHunt />);
  const gameList = [
    { name: "Box Hunt", component: <BoxHunt /> },
    { name: "Adivina el número", component: <GuessTheNumber /> },
  ];

  const HandlerGame = ({ component = game }) => {
    return component;
  };

  return (
    <div className="games">
      <div className="gameList">
        {gameList.map((g) => (
          <div className="game-item" onClick={() => setGame(g.component)}>
            {g.name}
          </div>
        ))}
      </div>

      <div>
        <HandlerGame component={game} />
      </div>
    </div>
  );
};
