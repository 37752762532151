import { useState } from "react";
//Redux
import { useDispatch } from "react-redux";
import { setComponentActive } from "../../reducers/userConfigSlice";
//Components
import { Games } from "../games";
import { Works } from "../works";
import "./style.css";

export const Menu = () => {
  const dispatch = useDispatch();
  const [active, setActive] = useState("Últimos trabajos");
  //const componentActive = useSelector((state) => state.aplicationConfig.userConfig.componentActive);
  const menuList = [
    { name: "Últimos trabajos", component: <Works /> },
    { name: "Laboratorio de minijuegos", component: <Games /> },
  ];

  const handleMenu = (m) => {
    dispatch(setComponentActive(m.component));
    setActive(m.name);
  };

  return (
    <div className="menu">
      <div className="menuList">
        {menuList.map((m) => (
          <div onClick={() => handleMenu(m)} style={active === m.name ? { color: "var(--primary-color)" } : {}}>
            {m.name}
          </div>
        ))}
      </div>
    </div>
  );
};
