import { ImLinkedin, ImLinkedin2, ImWhatsapp } from "react-icons/im";
import "./style.css";

export const Contact = () => {
  function obtenerDiaMesAbreviado(fecha = new Date()) {
    const meses = ["ENE", "FEB", "MAR", "ABR", "MAY", "JUN", "JUL", "AGO", "SEP", "OCT", "NOV", "DIC"];
    const dia = fecha.getDate(); // Día del mes
    const mes = meses[fecha.getMonth()]; // Mes abreviado
    return `${dia} ${mes}`;
  }

  return (
    <div style={{ display: "flex", justifyContent: "space-between" }}>
      <div style={{ fontSize: "2rem" }}>{obtenerDiaMesAbreviado()}</div>
      <div>
        <div className="box-title">Contacto</div>
        <div className="contact">
          <div>
            <a href="https://wa.me/34676227789" target="_blank" rel="noreferrer">
              <ImWhatsapp />
            </a>
          </div>
          <div>
            <a href="https://www.linkedin.com/in/antonio-ruiz-ruiz/" target="_blank" rel="noreferrer">
              <ImLinkedin2 />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
