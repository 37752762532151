//Redux
import { useSelector } from "react-redux";
//Components
import { Works } from "../works";
import { Badget } from "../badget";
import "./style.css";
import { RiCameraLensFill } from "react-icons/ri";
import { Menu } from "../menu";

export const BadgetMain = () => {
  const componentActive = useSelector((state) => state.aplicationConfig.userConfig.componentActive);

  const Component = () => {
    return componentActive !== null ? componentActive : <Works />;
  };
  /**
   * TODO
   * El menu meterlo en la tablet y crear en el sidebar derecho otras gráficas
   */

  return (
    <div className="badgetMain">
      <div class="tablet">
        <div class="camera">
          <RiCameraLensFill size={"0.5rem"} color={"black"} />
        </div>
        <div class="screen">
          <Menu />
          <Component />
        </div>
      </div>
    </div>
  );
};
